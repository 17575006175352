import React from "react";
import { Typography, Container, Box } from "@mui/material";
import Client from "./Client";
import { clients } from "../../data";

const Clients = () => {
  return (
    <Container sx={{ mt: 2, textAlign: "center" }}>
      <Typography variant="h3">
        <span style={{ color: "#FF5722", fontWeight: "bold" }}>Our</span>{" "}
        Clients
      </Typography>
      <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          position: "relative",
          "&:hover .slider": {
            animationPlayState: "paused",
          },
        }}
      >
        <Box
          className="slider"
          sx={{
            display: "flex",
            animation: "scroll 10s linear infinite",
          }}
        >
          {clients.map((client, index) => (
            <Box key={index}>
              <Client
                client={client}
                sx={{ maxWidth: "300px", flexShrink: 0 }}
              />
            </Box>
          ))}
          {clients.map((client, index) => (
            <Box
              key={`duplicate-${index}`}
              sx={{ maxWidth: "300px", flexShrink: 0 }}
            >
              <Client client={client} />
            </Box>
          ))}
        </Box>
      </Box>

      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </Container>
  );
};

export default Clients;
